import IDM from '@creator.co/module-identity-client'
import axios from 'axios'
import { createContext, useContext, useEffect, useState } from 'react'
import Cookies from 'universal-cookie'

import { FeatureContext } from './FeatureContext'
import { API_URL, AUTH_DASHBOARD_DOMAIN, IDM_URL } from '../constants'

export const GlobalContext = createContext(null)

export const GlobalContextProvider = props => {
  const [idm, setIdm] = useState()
  const [idmToken, setIdmToken] = useState()
  const [menuExpanded, setMenuExpanded] = useState(false)
  const toggleMenu = () => setMenuExpanded(!menuExpanded)
  const { hasFeatureFlag } = useContext(FeatureContext)
  const cookies = new Cookies()

  useEffect(() => {
    const client = new IDM({
      apiEndpoint: IDM_URL,
      externalAuthDomain: AUTH_DASHBOARD_DOMAIN,
      externalAuthPath: '/login',
      externalAuth: true,
    })

    if (hasFeatureFlag('main')) {
      setIdmToken(cookies.get('idm_token_v1'))
      setIdm(client)
    } else {
      client.load().then(async loaded => {
        console.log('IDM is logged in?', await client.isLogged(), { loaded })

        if (loaded) {
          setIdmToken(await client.session.getToken())
        }

        setIdm(client)
      })
    }
  }, [])

  const getCategories = async () => {
    const { data } = await axios.get(`${API_URL}/categories`)
    return data
  }

  const contextValues = {
    idm,
    idmToken,
    setIdmToken,
    menuExpanded,
    toggleMenu,
    getCategories,
  }

  return <GlobalContext.Provider value={contextValues}>{props.children}</GlobalContext.Provider>
}
